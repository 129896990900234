import { FieldArray } from 'redux-form'

export interface Props {
  name: string
  component: any
  recruiters?: any
  formValues?: any
  canEdit?: boolean
  fetchRecruitersWithOthers?: (withOthers: boolean) => void
}

export const FieldCustomArray = FieldArray as new () => FieldArray<Props>
