import { asyncAction } from 'lib/actionCreators'
import { createRoutine, promisifyRoutine } from 'redux-saga-routines'

export const inviteRecruiters = createRoutine('manager/INVITE_RECRUITERS')
export const inviteRecruitersPromiseCreator = promisifyRoutine(inviteRecruiters)

export const declineInvitationToRecruiter = createRoutine('manager/DECLINE_INVITATION_TO_RECRUITER')
export const declineInvitationToRecruiterPromiseCreator = promisifyRoutine(
  declineInvitationToRecruiter
)

export const recruitersList = asyncAction('manager/RECRUITERS_LIST')
export const recruitersListWithOthers = asyncAction('manager/RECRUITERS_LIST_WITH_OTHERS')

export const recruiterProfile = asyncAction('manager/RECRUITER_PROFILE')
export const allRecruiters = asyncAction('manager/ALL_RECRUITERS')
export const allRecruitersNewList = asyncAction('manager/ALL_RECRUITERS_NEW_LIST')
export const invitedRecruitersList = asyncAction('manager/INVITED_RECRUITERS_LIST')
export const resendInvitationToRecruiter = asyncAction('manager/RESEND_INVITATION_TO_RECRUITER')
export const recruiterProfileSkills = asyncAction('manager/RECRUITER_PROFILE_SKILLS')
export const recruiterProfileJobs = asyncAction('manager/RECRUITER_PROFILE_JOBS')
export const recruiterProfileCandidates = asyncAction('manager/RECRUITER_PROFILE_CANDIDATES')

export const deleteRecruiter = createRoutine('manager/DELETE_RECRUITER')
export const deleteRecruiterPromiseCreator = promisifyRoutine(deleteRecruiter)
