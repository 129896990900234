import { put, call, select } from 'redux-saga/effects'
import { error403, modalIsLoading, pageIsLoading, skeleton } from 'domain/loading'
import { allRecruitersSelector } from 'domain/manager/selectors'
import { transformUser } from 'domain/manager/helpers'
import * as Actions from './actions'
import * as M from 'domain/env'
import * as Router from 'domain/router'
import Api from 'domain/api'
import I from 'immutable'

interface Props {
  payload?: Array<{ email: string }>
  type: string
}

interface PropsGetProfile {
  payload?: boolean
  type?: string
}

interface PropsRecruitersList {
  payload?: {
    withOthers?: boolean
  }
  type?: string
}

interface Params {
  order?: string
  filter?: FilterType
  page?: number
}

interface FilterType {
  countryId?: number
  cityId?: number
  tagIds?: string[]
}

export function* ensureInviteRecruiters({ payload }: Props) {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield put({ type: modalIsLoading, payload: true })
    if (!payload) {
      yield put({ type: Actions.inviteRecruiters.SUCCESS, payload: I.fromJS([]) })
      return
    }
    const {
      data: { data }
    } = yield call(Api.inviteRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      data: { emails: payload }
    })
    yield put({ type: Actions.inviteRecruiters.SUCCESS, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.inviteRecruiters.FAILURE,
      err
    })
  } finally {
    yield put({ type: modalIsLoading, payload: false })
  }
}

export function* ensureRecruitersList(props?: PropsRecruitersList) {
  const withOthers = (props && props.payload && props.payload.withOthers) || false
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      params: withOthers ? { withOthers } : undefined
    })
    yield put({ type: Actions.recruitersList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruitersList.failure,
      err
    })
  }
}

export function* ensureGetAllRecruiters(props?: { payload?: Params; type: string }) {
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload || props.payload.page === 1) {
    yield put({ type: skeleton, payload: true })
    yield put({ type: pageIsLoading, payload: true })
  }
  const params = (props && props.payload) || {}
  try {
    const { data } = yield call(Api.getAllRecruiters, {
      headers: { Authorization: `Bearer ${headers}` },
      params
    })
    const page = params.page
    const total = data.meta.total
    const prevState = yield select(allRecruitersSelector)
    if (!params || !page || page === 1 || total === prevState.size) {
      yield put({ type: Actions.allRecruitersNewList.success, payload: I.fromJS(data) })
    } else {
      yield put({ type: Actions.allRecruiters.success, payload: I.fromJS(data) })
    }
  } catch (err) {
    yield put({
      type: Actions.allRecruiters.failure,
      err
    })
  } finally {
    yield put({ type: skeleton, payload: false })
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetRecruiterProfile(props?: PropsGetProfile) {
  yield put({ type: error403, payload: false })
  const headers = yield select(M.userToken)
  if (!headers) return
  if (!props || !props.payload) {
    yield put({ type: Actions.recruiterProfile.success, payload: I.fromJS({}) })
    yield put({ type: pageIsLoading, payload: true })
  }
  const {
    params: { id }
  } = yield select(Router.matchRoutes)
  try {
    const {
      data: { data }
    } = yield call(Api.getRecruiterProfile, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    const {
      data: { data: actionsList }
    } = yield call(Api.getActionsList, {
      headers: { Authorization: `Bearer ${headers}` },
      id
    })
    yield put({
      type: Actions.recruiterProfile.success,
      payload: transformUser(I.fromJS({ ...data, showSendInvite: actionsList.canAssign }))
    })
  } catch (err) {
    const { statusCode } = err.response.data
    if (statusCode === 403 || statusCode === 404) {
      yield put({ type: error403, payload: true })
    }
    yield put({
      type: Actions.recruiterProfile.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureInvitedRecruitersList() {
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.getInvitedRecruiters, {
      headers: { Authorization: `Bearer ${headers}` }
    })
    yield put({ type: Actions.invitedRecruitersList.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.invitedRecruitersList.failure,
      err
    })
  }
}

export function* ensureResendInvitationToRecruiter(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const {
      data: { data }
    } = yield call(Api.resendInvitationToRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: Actions.resendInvitationToRecruiter.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.resendInvitationToRecruiter.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeclineInvitationToRecruiter(props: {
  payload: { id: number }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.declineInvitationToRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: Actions.declineInvitationToRecruiter.SUCCESS, payload: props.payload.id })
  } catch (err) {
    yield put({
      type: Actions.declineInvitationToRecruiter.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureDeleteRecruiter(props: { payload: { id: number }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    yield call(Api.deleteRecruiter, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: Actions.deleteRecruiter.SUCCESS, payload: props.payload.id })
  } catch (err) {
    yield put({
      type: Actions.deleteRecruiter.FAILURE,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetRecruiterSkills(props: { payload: { id: number }; type: string }) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getRecruiterSkills, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id
    })
    yield put({ type: Actions.recruiterProfileSkills.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterProfileSkills.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetRecruiterJobs(props: {
  payload: { id: number; params?: { status?: string; page?: number; perPage?: number } }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getRecruiterJobs, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id,
      params: props.payload.params
    })
    yield put({ type: Actions.recruiterProfileJobs.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterProfileJobs.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}

export function* ensureGetRecruiterCandidates(props: {
  payload: { id: number; params?: { page?: number; perPage?: number } }
  type: string
}) {
  yield put({ type: pageIsLoading, payload: true })
  const headers = yield select(M.userToken)
  if (!headers) return
  try {
    const { data } = yield call(Api.getRecruiterCandidates, {
      headers: { Authorization: `Bearer ${headers}` },
      id: props.payload.id,
      params: props.payload.params
    })
    yield put({ type: Actions.recruiterProfileCandidates.success, payload: I.fromJS(data) })
  } catch (err) {
    yield put({
      type: Actions.recruiterProfileCandidates.failure,
      err
    })
  } finally {
    yield put({ type: pageIsLoading, payload: false })
  }
}
